@import url(https://fonts.googleapis.com/css?family=Shrikhand|Yatra+One);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1{
  Font-Family: 'Raleway', Sans-Serif;
Font-Size: 70px;
}
.welcome-to-da-boof{
  font-family: 'Yatra One', cursive;
  margin-bottom:0px;

}

h2,h3,p{
  Font-Family: 'Open Sans', Sans-Serif;
  color:whiTE;
  
}

.boof-it-baby{
  font-family: 'Shrikhand', cursive;
  color:#F2BAC9;
  -webkit-transform: rotate(20deg);
          transform: rotate(20deg);
  
}
.App {
  text-align: center;
  background: url('/capitol.jpg');

  min-height: 100vh;
}


.pic-of-my-boy-brett img{
width: 50%;
}



.App-header {


  display: flex;
  flex-direction: column;
  align-items: center;

  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}



